import { Player } from '@lottiefiles/react-lottie-player';
import { Row, Col, Typography, Card, Divider, Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import loadingLottie from '../../static/lotties/loading.json';
import pendingLottie from '../../static/lotties/pending.json';
import progressLottie from '../../static/lotties/progress_loading.json';
import inProgressLottie from '../../static/lotties/in_progress.json';
import almostDoneLottie from '../../static/lotties/almost_done.json';
import readyLottie from '../../static/lotties/ready.json';
import FeatherIcon from 'feather-icons-react';
import { ExperimentOutlined, GoldOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import { API } from '../../utility/utils';
import moment from 'moment-timezone';

moment.locale('es-mx');

const Lottie = ({ status }) => {
  if (status === 'PENDING') {
    return (
      <>
        <Row>
          <Col span={24}>
            <Player autoplay loop src={pendingLottie} style={{ height: '250px', width: '250px', marginTop: '-30px' }} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Player
              autoplay
              loop
              src={progressLottie}
              style={{ height: '300px', width: '300px', marginTop: '-180px' }}
            />
          </Col>
        </Row>
        <Row style={{ height: 1, marginTop: '-140px' }} />
        <Row justify="space-around">
          <Col span={14} style={{ textAlign: 'center', fontSize: 13 }}>
            <Typography.Text strong>Estamos preparando lo necesario para comenzar los análisis</Typography.Text>
          </Col>
        </Row>
      </>
    );
  }
  if (status === 'IN_PROGRESS') {
    return (
      <>
        <Row>
          <Col span={24}>
            <Player
              autoplay
              loop
              src={inProgressLottie}
              style={{ height: '250px', width: '250px', marginTop: '-30px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Player
              autoplay
              loop
              src={progressLottie}
              style={{ height: '300px', width: '300px', marginTop: '-180px' }}
            />
          </Col>
        </Row>
        <Row style={{ height: 1, marginTop: '-140px' }} />
        <Row justify="space-around">
          <Col span={14} style={{ textAlign: 'center', fontSize: 13 }}>
            <Typography.Text strong>Estamos trabajando en tus análisis</Typography.Text>
          </Col>
        </Row>
      </>
    );
  }
  if (status === 'AUTHORIZED' || status === 'DELIVERED_BY_ANALISTS') {
    return (
      <>
        <Row>
          <Col span={24}>
            <Player
              autoplay
              loop
              src={almostDoneLottie}
              style={{ height: '250px', width: '250px', marginTop: '-30px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Player
              autoplay
              loop
              src={progressLottie}
              style={{ height: '300px', width: '300px', marginTop: '-180px' }}
            />
          </Col>
        </Row>
        <Row style={{ height: 1, marginTop: '-140px' }} />
        <Row justify="space-around">
          <Col span={14} style={{ textAlign: 'center', fontSize: 13 }}>
            <Typography.Text strong>
              Estamos trabajando en los últimos detalles para entregarte los resultados
            </Typography.Text>
          </Col>
        </Row>
      </>
    );
  }
  if (status === 'DELIVERED_TO_CLIENT') {
    // TODO: Still need to define this status
    return (
      <>
        <Row>
          <Col span={24}>
            <Player autoplay keepLastFrame src={readyLottie} style={{ height: '250px', width: '250px' }} />
          </Col>
        </Row>
        <Row style={{ height: 1, marginTop: '0px' }} />
        <Row justify="space-around">
          <Col span={14} style={{ textAlign: 'center', fontSize: 13 }}>
            <Typography.Text strong>Terminamos, pronto te enviaremos los resultados!</Typography.Text>
          </Col>
        </Row>
      </>
    );
  }

  return null;
};
const WorkOrder = () => {
  const [loading, setLoading] = useState(true);
  const [workOrder, setWorkOrder] = useState(null);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const getWorkOrder = async () => {
    try {
      const res = await API.get(`work-order-public/${id}`);
      setLoading(false);
      setWorkOrder(res.data.data);
    } catch (e) {
      history.replace('/not-found');
    }
  };

  const selectInitialWorkOrder = () => {
    const localWorkOrder = { ...workOrder };
    if (localWorkOrder.childWorkOrders) delete localWorkOrder.childWorkOrders;
    setSelectedWorkOrder(localWorkOrder);
  };

  useEffect(() => {
    if (workOrder) {
      selectInitialWorkOrder();
    }
  }, [workOrder]);

  useEffect(() => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (id && regexExp.test(id)) {
      getWorkOrder(id);
    } else {
      history.replace('/not-found');
    }
  }, []);

  const handleCardClick = workOrderFolio => {
    if (workOrder.folio === workOrderFolio) {
      const localWorkOrder = { ...workOrder };
      if (localWorkOrder.childWorkOrders) delete localWorkOrder.childWorkOrders;
      setSelectedWorkOrder(localWorkOrder);
    } else {
      const childWorkOrder = workOrder.childWorkOrders.find(childWo => childWo.folio === workOrderFolio);
      const localWorkOrder = {
        ...workOrder,
        folio: childWorkOrder.folio,
        status: childWorkOrder.status,
        product: childWorkOrder.product,
        determinations: childWorkOrder.determinations,
      };
      if (localWorkOrder.childWorkOrders) delete localWorkOrder.childWorkOrders;
      setSelectedWorkOrder(localWorkOrder);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {!loading && selectedWorkOrder ? (
        <Row style={{ padding: '20px 15px' }}>
          <img
            src={require(`../../static/img/logo.png`)}
            alt=""
            style={{ height: '50px', position: 'absolute', top: 5, left: 15 }}
          />
          <Col span={24}>
            <Row justify="center" style={{ marginBottom: 30 }}>
              <Col>
                <Typography.Title level={5}>Tu muestra {selectedWorkOrder.folio}</Typography.Title>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Text>Fecha de entrega</Typography.Text>
              </Col>
              <Col span={24} style={{ textAlign: 'center', fontSize: 20 }}>
                <Typography.Text strong>
                  {moment(selectedWorkOrder.deliveryDateClient).format('dddd, DD [de] MMMM [de] YYYY')}
                </Typography.Text>
              </Col>
            </Row>

            <Lottie status={selectedWorkOrder.status} />

            <Row justify="space-around" style={{ marginTop: 20 }}>
              <Col span={24}>
                <Card>
                  <Card.Meta title={selectedWorkOrder.clientName} description={selectedWorkOrder.clientAddress} />
                </Card>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Row gutter={15}>
                  <Col>
                    <FeatherIcon icon="package" color="#d61a5e" />
                  </Col>
                  <Col>
                    <Typography.Text strong style={{ fontSize: 16 }}>
                      Detalle de muestra
                    </Typography.Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Typography.Text strong style={{ fontSize: 15 }}>
                      {selectedWorkOrder.product.name}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <Typography.Text>Número de la muestra</Typography.Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Typography.Text strong>{selectedWorkOrder.folio}</Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <Typography.Text>Fecha de recepción</Typography.Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Typography.Text strong>
                          {moment(selectedWorkOrder.createdAt).format('DD/MM/YYYY')}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <Typography.Text>Número de lote</Typography.Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Typography.Text strong>{selectedWorkOrder.product.lotNumber}</Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <Typography.Text>Presentación</Typography.Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Typography.Text strong>{selectedWorkOrder.product.presentation}</Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Row gutter={15}>
                  <Col>
                    <ExperimentOutlined style={{ color: '#d61a5e', fontSize: 24 }} />
                  </Col>
                  <Col>
                    <Typography.Text strong style={{ fontSize: 16 }}>
                      Análisis de muestra
                    </Typography.Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  {selectedWorkOrder.determinations.map(det => (
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <Typography.Text>{det.name}</Typography.Text>
                        </Col>
                        <Col span={24}>
                          <Typography.Text style={{ color: '#b9b9b9', fontSize: 12 }}>
                            {det.analyticReference}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            {workOrder.childWorkOrders && workOrder.childWorkOrders.length > 0 ? (
              <>
                <Divider />
                <Row>
                  <Col span={24}>
                    <Row gutter={15}>
                      <Col>
                        <GoldOutlined style={{ color: '#d61a5e', fontSize: 24 }} />
                      </Col>
                      <Col>
                        <Typography.Text strong style={{ fontSize: 16 }}>
                          Todas las muestras ({workOrder.childWorkOrders.length + 1} en total)
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <Carousel
                              style={{
                                padding: '20px 20px 25px 20px',
                                background: 'rgba(0, 0, 0, 0.06)',
                                borderRadius: '3%',
                              }}
                            >
                              <Card onClick={() => handleCardClick(workOrder.folio)}>
                                <Card.Meta title={`Muestra ${workOrder.folio}`} description={workOrder.product.name} />
                              </Card>
                              {workOrder.childWorkOrders.map(childWo => (
                                <Card key={childWo.folio} onClick={() => handleCardClick(childWo.folio)}>
                                  <Card.Meta title={`Muestra ${childWo.folio}`} description={childWo.product.name} />
                                </Card>
                              ))}
                            </Carousel>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Row justify="space-around" align="middle" style={{ height: '100vh' }}>
          <Player autoplay loop src={loadingLottie} style={{ height: '300px', width: '300px' }} />
        </Row>
      )}
    </>
  );
};

export default WorkOrder;
