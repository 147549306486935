import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import Amplify from 'aws-amplify';
import moment from 'moment';
import 'moment/locale/es-mx';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import config, { amplifyConfig } from './config/config';
import AdminWorkOrder from './container/workOrder/AdminWorkOrder';
import WorkOrder from './container/workOrder/WorkOrder';
import WorkOrderNotFound from './container/workOrder/WorkOrderNotFound';
import store from './redux/store';
import './static/css/style.less';

Amplify.configure(amplifyConfig);
const { theme } = config;

moment.locale('es');

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={esES}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route exact path="/estatus-analisis/:id" component={WorkOrder} />
            <Route exact path="/verificacion-folio/:id" component={AdminWorkOrder} />
            <Route exact path="/not-found" component={WorkOrderNotFound} />
            <Route render={() => <Redirect to="/not-found" />} />
          </Switch>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
