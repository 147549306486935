/* eslint-disable no-unused-vars */
import { Player } from '@lottiefiles/react-lottie-player';
import { Card, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import loadingLottie from '../../static/lotties/loading.json';
import { API } from '../../utility/utils';

const AdminWorkOrder = () => {
  const [loading, setLoading] = useState(true);
  const [adminWorkOrder, setAdminWorkOrder] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const getAdminWorkOrder = async () => {
    try {
      const res = await API.get(`work-order-public-verify/${id}`);
      setLoading(false);
      setAdminWorkOrder(res.data.data);
    } catch (e) {
      history.replace('/not-found');
    }
  };

  useEffect(() => {
    if (id) {
      getAdminWorkOrder(id);
    } else {
      history.replace('/not-found');
    }
  }, []);

  return (
    <>
      {!loading && adminWorkOrder ? (
        <Row style={{ padding: '20px 15px' }}>
          <img
            src={require(`../../static/img/logo.png`)}
            alt=""
            style={{ height: '50px', position: 'absolute', top: 5, left: 15 }}
          />
          <Col span={24}>
            <Row justify="end" style={{ marginBottom: 30, textAlign: 'center' }}>
              <Col>
                <Typography.Title level={4}>Sitio Web:</Typography.Title>
                <a href="https://www.laboratoriociaj.com.mx">www.laboratoriociaj.com.mx</a>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                <Typography.Title level={3}>Validación de Resultados</Typography.Title>
                <Typography.Title level={3} style={{ marginTop: 0 }}>
                  DESU Operadora
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="space-around" style={{ marginTop: 20 }}>
              <Col span={24}>
                <Card style={{ marginBottom: 10 }}>
                  <Row justify="space-between" gutter={16}>
                    <Col span={12}>
                      <Typography.Text>Número de la Muestra</Typography.Text>
                      <br />
                      <Typography.Text strong>{adminWorkOrder.folio}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text>Fecha de Ingreso</Typography.Text>
                      <br />
                      <Typography.Text strong>{adminWorkOrder.createdAt}</Typography.Text>
                    </Col>
                  </Row>
                  <Row justify="space-between" gutter={16} style={{ textAlign: 'center', marginTop: 20 }}>
                    <Col span={24}>
                      <Typography.Text>IDENTIFICACIÓN DE LA MUESTRA</Typography.Text>
                      <br />
                      <Typography.Text strong>{adminWorkOrder.productName.toUpperCase()}</Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row justify="space-around">
              <img src={require(`../../static/img/desu_green_check.png`)} alt="" style={{ height: '250px' }} />
            </Row>
          </Col>
        </Row>
      ) : (
        <Row justify="space-around" align="middle" style={{ height: '100vh' }}>
          <Player autoplay loop src={loadingLottie} style={{ height: '300px', width: '300px' }} />
        </Row>
      )}
    </>
  );
};

export default AdminWorkOrder;
