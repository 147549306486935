import { Auth as AmplifyAuth } from 'aws-amplify';
import axios from 'axios';
import env from '../config/constants';

const API = {
  endpoint: env.apiUrl,
  get: async path => {
    const r = await axios.get(`${API.endpoint}/${path}`, {
      headers: {
        'x-api-key': env.apiKey,
      },
    });
    return r;
  },
  put: async (path, fields) => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();
    const r = await axios.put(`${API.endpoint}/${path}`, fields, {
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return r;
  },
  post: async (path, fields) => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();
    const r = await axios.post(`${API.endpoint}/${path}`, fields, {
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return r;
  },
  recoverPassword: async (path, fields) => {
    const r = await axios.post(`${API.endpoint}/${path}`, fields, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return r;
  },
  delete: async path => {
    const idToken = (await AmplifyAuth.currentSession()).getIdToken().getJwtToken();

    const r = await axios.delete(`${API.endpoint}/${path}`, {
      headers: {
        Authorization: idToken,
      },
    });

    return r;
  },
};

const codeParse = {
  CodeMismatchException: 'El código de recuperación es incorrecto',
  UserNotFoundException: 'El usuario no existe',
  LimitExceededException: 'Has superado el limite de códigos enviados, espera unos minutos y vuelve a intentarlo',
  ExpiredCodeException: 'El código de verificación expiró',
  TooManyFailedAttemptsException:
    'Has superado el limite de intentos de inicio de sesión, espera unos minutos y vuelve a intentarlo',
  NotAuthorizedException: 'Los datos ingresados son incorrectos',
};

const errorParser = code => {
  const error = codeParse[code];
  return error;
};
export { API, errorParser };
