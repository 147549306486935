import { Button, Result } from 'antd';
import React from 'react';

const WorkOrderNotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Lo sentimos, la página que buscabas no existe."
      extra={
        <Button
          style={{ backgroundColor: '#d61a5e', borderColor: '#d61a5e' }}
          type="primary"
          href="https://laboratoriociaj.com.mx/"
        >
          Inicio
        </Button>
      }
    />
  );
};

export default WorkOrderNotFound;
